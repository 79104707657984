

.col, .col-12 {
    margin-top: 2rem;

    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;

}

.cardLook {
    border: 1px solid rgba(0,0,0,0.125);
    background-color: white;
    
    padding: 15px;
}

.card {
    text-align: center;
    min-height: 450px;
    background-color: #ffffff;
    
}

.card img {
    margin-bottom: 1rem; 
    box-shadow: 0px 5px 15px #00000077;
}

.cardFixer p {
    text-align: left;
}

.rowBody {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 40px #ffffff;
}

.pdf-buttons {
    all: unset !important;
    vertical-align: middle !important;
    height: 1.125rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    cursor: pointer !important;
}

.rowColor1 {
    background-color: #faf7f7;
    background-image: url("../images/skulls.png");
    box-shadow: 0px 10px 20px #000000;
    overflow: hidden;
    padding-bottom: 8rem;
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.phoneBig {
    display: inline;
}

.phoneSmall {
    display: none;
}

.rowColor2 {
    background-color: #ffffff;
background-image: url("../images/green-gobbler.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.rowColor2 h2, .rowColor2 h3 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.divider {
    box-shadow: 0px 1px 0px rgba(127,127,127,0.2);

    padding-bottom: 2rem;
}

.attentionGrabberMini {
    background-size: cover;
}

.attentionGrabber {
    background-position: center !important; 

    margin: 0rem !important; 
    padding: 0rem;

    width: 100%;
    height: 650px;

    color:white !important;
    box-shadow: inset 0px -5px 100px #000000;

    z-index: -1;
}

.pageDetailTextContainer {
    margin-top: 0rem; 
    width: 100%; 
    height: 100%; 
    background-color: rgba(0,0,0,0.7); 
    text-align: center; 
    padding: 1rem;
}

/*deprecated - here for the sake of older versions of the website, don't touch.*/
.footerSpacing {
    margin-top: 2rem;
}

/* Remove ul dots in Edge*/
.navbar > ul {
    list-style-type:none !important;
}

.navbar {
    box-shadow: 0px 10px 30px rgba(0,0,0,0.7); 
    font-size: 1.1rem;
    margin-bottom: 3rem;
}



.negatePadding {
    padding-left: 0px;
}

.headerPadder h2 {
    margin-top: 3rem;
}

.headerPadder h3 {
    margin-top: 2rem;
}
.logo {
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    height: 7vh;
}

.logoContainer {
    height: calc(7vh + 3rem);
    text-align: left;
}

.hiddenLogo{
    height: 0px;
}

.bonusDucks {
    padding-left:1rem;
    padding-right:1rem;

    margin-bottom:2rem;
    margin-top:3rem;
}

p {
    margin-top: 1rem;
}

h1 {
    margin-bottom: 1rem;
    margin-top: 2rem;

    text-align: center;
}

h2 {
    margin-bottom: 1rem;

}

.padder {
    height:208px;
}

header {
    background-color: #ffffff;
    background-image: url('../images/green-gobbler.png');
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
    opacity: 1;
    background-repeat: repeat;
    background-size: auto;
    margin: 0px;

    top: 0px !important;
    position: fixed;
    width: 100%;

    z-index: 10;
}

.phantom-padder {
    padding-top: calc(7vh + 3rem + 58.4px);
    display: block;
    
    max-height: 0rem;
}

body {
    background-color: #ffffff;
    background-image: url('../images/green-gobbler.png');
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
    opacity: 1;
    background-repeat: repeat;
    background-size: auto;
    margin: 0px;
}

footer {
    font-size: 0.8rem;
}

.jumbotron {
    background-image: url("https://www.transparenttextures.com/patterns/noisy-net.png"), url("../images/jumbo_bg.gif");
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    background-color: black;

    padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
}

.mission-statement {
    height: 650px; 
    /* background-image: url('../images/ms_bg_xl.jpg');  */
    background-position: center; 
    margin: 0rem; 
    padding: 0rem; 
    margin-bottom: 0rem;
}

.mission-statement blockquote p {
    text-align: justify; 
    line-height: 2rem; 
    font-size: 12pt; 
    color: white;
}

.mission-statement blockquote footer {
    color:white; 
    margin-top: 1rem;
}

.mission-statement blockquote Button {
    margin-top: 2rem;

}

.not-found {
    min-height: calc(100vh - 67.2px - 7vh - 3rem - 58.4px);

    background-color: #000524;
background-image: url("https://www.transparenttextures.com/patterns/bright-squares.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.mapouter {
    text-align: right;
    height: 450px;
    width: 100%;
    margin: 2rem !important;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 450px;
    width: 100%;
}

.bodyPadding {
    margin-top: 3rem;
}



@media all and (max-width: 992px), (max-height: 700px) {
    .logo {
        margin:0rem;
        height: 0px !important;
    }

    .not-found {
        min-height: calc(100vh - 67.2px - 70px);
    }

    .logoContainer {
        height: 0px;
    }

    .padder {
        height: 60px !important;
    }

    .phantom-padder {
        padding-top: 70px;
        max-height: 0rem;
    }

    .hiddenLogo{
        height: 54px;
    }

    .phoneBig {
        display: none;
    }
    
    .phoneSmall {
        display: inline;
    }
}
